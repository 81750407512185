/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';

import PageTitle from '../components/PageTitle';
import PageHeader from '../components/PageHeader';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { theme } from '../constants/theme';
import PersonDrawer from '../components/PersonDrawer';

export const query = graphql`
  query Contacts($id: String) {
    prismic {
      allContacts(id: $id) {
        edges {
          node {
            sections {
              answer
              question
            }
            title
            _meta {
              lang
            }
            team_title
            team {
              biography
              email
              image
              job_title
              name
              url {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
 }
`;

const Section = ({ question, answer }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <SectionContainer open={isOpen} onClick={() => setOpen(!isOpen)}>
      <Question>{question}</Question>
      <Answer open={isOpen}>{RichText.render(answer)}</Answer>
    </SectionContainer>
  );
};

const TeamSection = ({ title, team }) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);

  const selectableTeam = team.filter((person) => !!person.biography);

  const onNext = useCallback(() => {
    const index = selectableTeam.findIndex((item) => item.name === selectedCollaborator.name);

    if (index === selectableTeam.length - 1) {
      setSelectedCollaborator(selectableTeam[0].primary);
    }

    setSelectedCollaborator(selectableTeam[index + 1]);
  }, [selectedCollaborator, selectableTeam]);

  const onPrevious = useCallback(() => {
    const index = selectableTeam.findIndex((item) => item.primary.name === selectedCollaborator.name);

    if (index === 0) {
      setSelectedCollaborator(selectableTeam[selectableTeam.length + 1]);
    }

    setSelectedCollaborator(selectableTeam[index - 1]);
  }, [selectedCollaborator, selectableTeam]);

  const selectedPerson = selectedCollaborator === null ? null : {
    name: selectedCollaborator && selectedCollaborator.name,
    homepage: selectedCollaborator && selectedCollaborator.url,
    job_title: selectedCollaborator && selectedCollaborator.job_title,
    image: selectedCollaborator && selectedCollaborator.image,
    biography: selectedCollaborator && selectedCollaborator.biography,
  };

  return (
    <SectionContainer open={isOpen}>
      <Question onClick={() => setOpen(!isOpen)}>{title}</Question>
      <TeamListContainer open={isOpen}>
        <TeamList>
          { team.map((person) => (
            <TeamListItem
              hasBio={person.biography}
              onClick={() => person.biography && setSelectedCollaborator(person)}
            >
              <ContributorName hasBio={person.biography}>{person.name}</ContributorName>
              {person.job_title && (<p>{person.job_title}</p>)}
              {person.email && (<p>{person.email}</p>)}
              {person.url && (<Web href={person.url.url} target="_blank">Web</Web>)}
            </TeamListItem>
          ))}
        </TeamList>
        <PersonDrawer
          hideNavigation={selectableTeam.length < 2}
          onNext={onNext}
          onPrevious={onPrevious}
          setSelectedCollaborator={() => setSelectedCollaborator(null)}
          selectedCollaborator={selectedPerson}
          onClose={() => setSelectedCollaborator(null)}
        />
      </TeamListContainer>
    </SectionContainer>
  );
};

const Web = styled.a`
  border-bottom: 1px solid ${theme.colors.red};
  text-decoration: none;
  line-height: 28px;
  display: inline-block;
  :hover {
    color: ${theme.colors.red}
  }
`;

const TeamListContainer = styled.div`
  position: relative;
  transition: max-height 1s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
  font-size: 18px;

  ${(props) => (props.open ? 'margin-top: 30px;' : 'margin-top: 0px;')}
  ${(props) => (props.open ? 'max-height: 5000px;' : 'max-height: 0px;')}
  ${(props) => (props.open ? 'opacity: 1;' : 'opacity: 0')}
`;

const ContributorName = styled.p`
  border-bottom: 1px solid ${theme.colors.red};
  border-bottom: ${(props) => (props.hasBio ? '1px solid #FC0505' : 'none')};
  :hover {
    color: ${theme.colors.red}
  }
  text-transform: uppercase;
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  cursor: ${(props) => (props.hasBio ? 'pointer' : 'inherit')};
`;

const TeamList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 50px;
  column-gap: 50px;
  margin-bottom: 0px;

  @media ${theme.breakpoints.size.m} {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    column-gap: 50px;
  }

  @media ${theme.breakpoints.size.s} {
    display: flex;
    grid-template-columns: 1;
  }
`;

const TeamListItem = styled.li`
  margin-bottom: 41px;
  text-align: center;
  width: 100%;

  p {
    text-align: center;
    line-height: 28px;
    margin: 0;
    @media ${theme.breakpoints.size.s} {
      text-align: center;
    }
  }

  @media ${theme.breakpoints.size.l} {
    &:nth-last-child(-n+4) {
      margin-bottom: 0;
    }
  }

  @media ${theme.breakpoints.size.m} {
    margin: 0px 0px 50px;

    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
  }

  @media ${theme.breakpoints.size.s} {
    text-align: center;
    width: 100%;
    margin: 0px 0px 25px !important;
    &:last-child {
      margin-bottom: 0;
    }

  }
`;

const Contact = ({ data, location }) => {
  const page = data.prismic.allContacts.edges[0];

  if (!page || !page.node) {
    return null;
  }

  const {
    title,
    sections,
    team,
    team_title,
    _meta: { lang },
  } = page.node;

  return (
    <Layout lang={lang} location={location}>
      <SEO title="Home" />
      <PageHeader>
        <PageTitle mainTitleText={title} />
      </PageHeader>
      <Content>
        { team && team_title && (
          <TeamSection title={team_title} team={team} />
        )}
        {sections && sections.map(({ question, answer }) => (
          <Section question={question} answer={answer} />
        ))}
      </Content>
    </Layout>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 117px 80px 150px 80px;


  @media ${theme.breakpoints.size.xl} {
    margin: 77px 80px 150px 80px;
  }

  @media ${theme.breakpoints.size.l} {
    margin: 77px 40px 100px 40px;
  }
  @media ${theme.breakpoints.size.s} {
    margin: 50px 20px 115px 20px;
  }
`;

const Question = styled.div`
  color: ${theme.colors.red};

  line-height: 28px;
  font-size: 28px;
  cursor: pointer;

  @media ${theme.breakpoints.size.xl} {
    font-size: 22px;
  }
  @media ${theme.breakpoints.size.l} {
    font-size: 18px;
  }

  &.team {
    margin-bottom: 30px;
  }
`;

const Answer = styled.div`
  margin: 0px auto;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  transition: max-height 1s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
  ${(props) => (props.open ? 'max-height: 5000px;' : 'max-height: 0px;')}
  ${(props) => (props.open ? 'opacity: 1;' : 'opacity: 0')}
  p {
      margin: 7px 0;
    }
    p:last-child {
      margin: 10px 0 0px 0;
    }

  width: 42%;

  @media ${theme.breakpoints.size.xl} {
    width: 55%;
    padding: 0 40px;
  }
  @media ${theme.breakpoints.size.l} {
    width: 60%;
    padding: 0px;
  }

  @media ${theme.breakpoints.size.m} {
    width: 76%;
  }

  @media ${theme.breakpoints.size.s} {
    width: 100%;
  }
`;

const SectionContainer = styled.div`
  border-top: 1px solid ${theme.colors.red};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: auto;
  position: relative;



  :last-child {
    border-bottom: 1px solid ${theme.colors.red};
  }
  padding: 44px 0;
  height: auto;

  @media ${theme.breakpoints.size.xl} {
    padding: 36px 0;
    height: auto;
  }

  @media ${theme.breakpoints.size.l} {
    padding: 35px 0;
    height: auto;
  }

`;

export default Contact;
